module.exports = {
  title: 'Vibecast',
  slug: 'vibecast-website',
  type: 'Responsive Web Application',
  role: 'Everything - Design & Development',
  published: 'April 2019 (Aquired April 2021)',
  copy:
    '<p><a title="Vibecast - The website builder designed for DJs" href="https://www.vibecast.com" target="_blank" rel="noopener">Vibecast</a> is a website builder designed specifically for DJs, built by myself (a fellow DJ) as a side project. Vibecast enables DJs to create a sleek, modern website in minutes without technical knowledge and all the features required to showcase their talents.</p><p>Vibecast uses three primary codebases and a marketing website. Laravel (PHP framework) powers the API server and user websites, and Vue.js (Javascript framework) for the Single Page Application (SPA) admin area and marketing website. Authenticated using JWT, deployed on AWS and taking subscription payments through Stripe.</p><p>I planned, prototyped, designed, developed (front and back-end) and deployed Vibecast myself between freelance work.</p><p>In April 2021, I sold Vibecast. You can <a href="https://jackbarham.medium.com/vibecast-is-looking-for-a-new-owner-is-that-you-c7cb0973ba56" target="_blank" rel="noopener">read the pre-sales pitch</a>, the <a href="https://jackbarham.medium.com/i-sold-vibecast-my-side-project-earning-over-65k-and-so-much-more-in-value-504b3f434bb7" target="_blank" rel="noopener">post-sale summary</a> and <a href="https://www.youtube.com/watch?v=Z6tAzuku5Dw" target="_blank" rel="noopener">watch how I sold Vibecast</a>.</p>',
  video: 'VufvKNVEAIo',
  images: [
    {
      image: 'https://static.jackbarham.com/jb/vibecast-1.jpg'
    },
    {
      image: 'https://static.jackbarham.com/jb/vibecast-4.jpg'
    },
    {
      image: 'https://static.jackbarham.com/jb/vibecast-2.jpg'
    }
  ]
}
